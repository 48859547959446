import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { inplayScoreboardUpdate } from '~SpectateStore/actions/inplayScoreboardUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const eventsAdapter = createEntityAdapter();

const eventsSlice = createSlice({
    name: 'events',
    initialState: eventsAdapter.getInitialState(),
    reducers: {
        addEvent: eventsAdapter.addOne,
        removeEvent: eventsAdapter.removeOne,
        updateSelectedMarketGroup: (state, { payload: { eventId, slug }}) => {
            state.entities[eventId]['selected_tab'] = slug;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            eventDataCleanUp,
            (state, { payload : eventId }) => {
                eventsAdapter.removeOne(state, eventId);
            },
        )
        .addCase(
            inplayEventData.fulfilled,
            (state, { payload : { event } }) => {
                if (!isEmpty(event)) {
                    return eventsAdapter.addOne(state, event);
                }

                return state;
            },
        )
        .addCase(
            inplayScoreboardUpdate,
            (state, { payload : { event } }) => {
                const { id, active, tradable, is_inplay } = event;
                // we might get event level tradable updates in inplay_update messages
                // nothing to update if we don't have the following
                if (id && active !== undefined && tradable !== undefined && is_inplay !== undefined) {
                    return eventsAdapter.upsertOne(state, event);
                }

                return state;
            },
        );
    },
});

export const { addEvent, removeEvent, updateSelectedMarketGroup } = eventsSlice.actions;
export default eventsSlice.reducer;
